table {
    width: 100%;
    font-family: 'Courier New', Courier, monospace;
}

table th {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    font-size: 14px;
}

table thead th {
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
}

table tbody tr:hover {
    font-weight: bold;
    cursor: pointer;

}

table tr {
    padding-bottom: 3px;
}

table td {
    text-align: left;
    padding-right: 15px;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

table .watched{
    color: black;
}

.mobile-table {
    padding-left: 20px;
    padding-right: 20px;
}

.browser-table {
    padding-left: 40px;
    padding-right: 40px;
}

.mobile-table td {
    font-size: 12px;
    min-width: 20px;
    max-width: 80px;
}

.browser-table td {
    font-size: 12px;
    min-width: 80px;
}

.browser-table .city {
    min-width: 270px;
}

.browser-table .watched-button{
    text-align: center;
    min-width: 5px;
}

.mobile-table .watched-button{
    text-align: center;
    min-width: 5px;
}
