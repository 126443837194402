.title h1 {
    padding-left: 44px;
    padding-bottom: 16px;
    padding-top: 40px;
    margin: 0;
    font-weight: bold;
    font-size: 24px;
    font-family:'Courier New', Courier, monospace;
}
.title p {
    padding-left: 48px;
    font-family:'Courier New', Courier, monospace;
    font-size: 12px;
    margin: 0;
    padding-bottom: 40px;
    cursor: pointer;
}

.title p:hover{
    font-weight: bold;
}