.browser-warning {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
}

.browser-warning-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    max-width: 400px;
    margin: 0 20px;
    position: relative;
    border-width: 1px;   
    border-style: solid; 
    border-color: lightgrey;
    border-radius: 0;
    font-family: 'Courier New', Courier, monospace;
    font-size: 12px;
}

.browser-warning h2 {
    margin-top: 0;
    text-align: center;

}

.browser-warning p {
    margin: 10px 0;
    text-align: center;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 14px;
    padding-bottom: 14px;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    padding: 0;
    color: #999;
}

.close-button:hover {
    color: #333;
}