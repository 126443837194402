.error {
    width: 100%;
    height: 100%;

}
.error p {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 16px;
    text-align: center;
    font-size: 16px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 12px;
}
